import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import PageTitle from './pageTitle/reducers';
import Clients from './clients/reducers';
import Diets from './diets/reducers';
import MealTemplates from './mealTemplates/reducers';
import Checkins from './checkins/reducers';
import TrainingPlans from './trainingPlans/reducers';

export default combineReducers({
    Auth,
    Layout,
    PageTitle,
    Clients,
    Diets,
    MealTemplates,
    Checkins,
    TrainingPlans,
});
