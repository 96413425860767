import { APICore } from './apiCore';

const api = new APICore();

function get_training_plan(params: { clientId: number }) {
    const baseUrl = `/training-plans?populate=*&userId=${params.clientId}`;
    return api.get(baseUrl, undefined);
}

async function update_training_plan(payload: any) {
    const baseUrl = `/training-plans/${payload.planId}`;

    try {
        if (payload.Plan_pdf) {
            const fileResp = await api.createWithFile('/upload', { files: payload.Plan_pdf[0] });
            const fileId = fileResp.data[0].id;
            return api.update(`${baseUrl}`, {
                data: {
                    id: payload.planId,
                    Plan_pdf: fileId,
                },
            });
        }
    } catch (e) {
        return null;
    }

    return null;
}

function create_training_plan(payload: any) {
    const baseUrl = `/training-plans/`;
    const data = { data: payload.data };
    return api.create(`${baseUrl}`, data);
}

function delete_training_plan(params: { trainingPlanId: number }) {
    const baseUrl = `/training-plans/${params.trainingPlanId}`;
    return api.delete(`${baseUrl}`);
}

export { delete_training_plan, update_training_plan, create_training_plan, get_training_plan };
