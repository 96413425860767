// constants
import { MealTemplatesActionTypes } from './constants';

export type MealTemplateActionType = {
    type: MealTemplatesActionTypes;
    payload: {} | string;
};

// common success
export const mealTemplatesApiResponseSuccess = (actionType: string, data: any): MealTemplateActionType => ({
    type: MealTemplatesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const mealTemplatesApiResponseError = (actionType: string, error: string): MealTemplateActionType => ({
    type: MealTemplatesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getMealTemplates = (): MealTemplateActionType => ({
    type: MealTemplatesActionTypes.GET_ALL_MEAL_TEMPLATES,
    payload: {},
});

export const findOneMealTemplate = (mealTemplateId: any): MealTemplateActionType => ({
    type: MealTemplatesActionTypes.GET,
    payload: { mealTemplateId },
});

export const createMealTemplate = (data: any): MealTemplateActionType => ({
    type: MealTemplatesActionTypes.CREATE_MEAL_TEMPLATE,
    payload: { data },
});

export const updateMealTemplate = (data: any, mealTemplateId: any): MealTemplateActionType => ({
    type: MealTemplatesActionTypes.UPDATE_MEAL_TEMPLATE,
    payload: { data, mealTemplateId },
});

export const deleteMealTemplate = (mealTemplateId: any): MealTemplateActionType => ({
    type: MealTemplatesActionTypes.DELETE_MEAL_TEMPLATE,
    payload: { mealTemplateId },
});
