import { Navigate, useLocation } from 'react-router-dom';

// helpers
import { APICore } from '../helpers/api/apiCore';

// hooks
import { useUser } from '../hooks';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const [loggedInUser] = useUser();

    const api = new APICore();
    const routeRestricted = roles && loggedInUser && roles.indexOf(loggedInUser?.role?.toLowerCase()) === -1;

    /**
     * not logged in so redirect to login page with the return url
     */
    if (api.isUserAuthenticated() === false && location.pathname !== '/auth/login') {
        return <Navigate to={'/auth/login'} state={{ from: location }} replace />;
    }

    // check if route is restricted by role
    if (routeRestricted) {
        // we never send back the role, but what i dont get is why the /clients doesn't return a 403?
        // role not authorised so redirect to home page
        // return <Navigate to={{ pathname: '/' }} />;
        // return <Navigate to="/access-denied" replace />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
