import React, { Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import HorizontalLayout from '../layouts/Horizontal/';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// constants
import { LayoutTypes } from '../constants';

// hooks
import { useRedux } from '../hooks';
import Loader from '../components/Loader';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
// const Register = React.lazy(() => import('../pages/auth/Register'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// Private routes
const Clients = React.lazy(() => import('../pages/Clients'));

// Checkins
// const CheckinsList = React.lazy(() => import('../pages/Checkins/CheckinsList'));

// Diets
const MealTemplates = React.lazy(() => import('../pages/MealTemplates'));
const ManageMealTemplates = React.lazy(() => import('../pages/MealTemplates/ManageMealTemplate'));

// Clients
const ManageClient = React.lazy(() => import('../pages/Clients/ManageClient'));

// dashboards
const DashBoard1 = React.lazy(() => import('../pages/dashboards/DashBoard1/'));

// chat
const ConversationsWithMessage = React.lazy(() => import('../components/chat/ConversationsWithMessageWrapper'));

// Loader
const loading = () => Loader;

// Errors
const Error404 = React.lazy(() => import('../pages/Error/Error404'));
const Error403 = React.lazy(() => import('../pages/Error/Error403'));
const Error500 = React.lazy(() => import('../pages/Error/Error500'));

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        // { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
                {
                    path: 'access-denied',
                    element: <LoadComponent component={Error403} />,
                },
                {
                    path: 'not-found',
                    element: <LoadComponent component={Error404} />,
                },
                {
                    path: 'server-error',
                    element: <LoadComponent component={Error500} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Coach'} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    element: <LoadComponent component={DashBoard1} />,
                },
                {
                    path: 'chat',
                    element: <LoadComponent component={ConversationsWithMessage} />,
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'clients',
                            children: [
                                { path: 'list', element: <LoadComponent component={Clients} /> },
                                { path: 'manage/:userId', element: <LoadComponent component={ManageClient} /> },
                            ],
                        },
                        {
                            path: 'meal-templates',
                            children: [
                                { path: 'list', element: <LoadComponent component={MealTemplates} /> },
                                {
                                    path: 'manage/:mealTemplateId',
                                    element: <LoadComponent component={ManageMealTemplates} />,
                                },
                            ],
                        },
                        // {
                        //     path: 'checkins',
                        //     children: [{ path: 'list', element: <LoadComponent component={CheckinsList} /> }],
                        // },
                    ],
                },
            ],
        },
        // Catch-all route
        { path: '*', element: <Navigate to="/not-found" replace /> },
    ]);
};

export { AllRoutes };
