import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import { get_diet, delete_diet, update_diet, create_diet } from '../../helpers';

// actions
import { dietsApiResponseSuccess, dietsApiResponseError } from './actions';

// constants
import { DietActionTypes } from './constants';

type GetDietPlan = {
    payload: {
        clientId: number;
    };
    type: string;
};

type UpdateDietPlan = {
    payload: {
        dietId: number;
        clientId: number;
        data: any;
    };
    type: string;
};

type DeleteDietPlan = {
    payload: {
        dietId: number;
    };
    type: string;
};

/**
 * Get clients
 * @param {*}
 */

function* getDiet({ payload: { clientId } }: GetDietPlan): SagaIterator {
    try {
        const response = yield call(get_diet, { clientId });
        const diet = response.data;
        yield put(dietsApiResponseSuccess(DietActionTypes.GET_DIET, diet));
    } catch (error: any) {
        yield put(dietsApiResponseError(DietActionTypes.GET_DIET, error));
    }
}

function* deleteDiet({ payload: { dietId } }: DeleteDietPlan): SagaIterator {
    try {
        const response = yield call(delete_diet, { dietId });
        const diet = response.data;
        yield put(dietsApiResponseSuccess(DietActionTypes.DELETE_DIET, diet));
    } catch (error: any) {
        yield put(dietsApiResponseError(DietActionTypes.DELETE_DIET, error));
    }
}

function* createDiet({ payload: { clientId } }: GetDietPlan): SagaIterator {
    try {
        const response = yield call(create_diet, { clientId });
        const diet = response.data;
        yield put(dietsApiResponseSuccess(DietActionTypes.CREATE_DIET, diet));
    } catch (error: any) {
        yield put(dietsApiResponseError(DietActionTypes.CREATE_DIET, error));
    }
}

function* updateDiet({ payload: { data, dietId, clientId } }: UpdateDietPlan): SagaIterator {
    try {
        // Call API with mapped diet object
        const response = yield call(update_diet, { data, dietId });
        const diet = response.data;

        // Dispatch success action
        yield put(dietsApiResponseSuccess(DietActionTypes.POST_UPDATE_DIET, diet));
        yield call(get_diet, { clientId });
    } catch (error: any) {
        // Dispatch error action
        yield put(dietsApiResponseError(DietActionTypes.POST_UPDATE_DIET, error));
    }
}

export function* watchGetDiet() {
    yield takeEvery(DietActionTypes.GET_DIET, getDiet);
}

export function* watchDeleteDiet() {
    yield takeEvery(DietActionTypes.DELETE_DIET, deleteDiet);
}

export function* watchPostEditDiet() {
    yield takeEvery(DietActionTypes.POST_UPDATE_DIET, updateDiet);
}
export function* watchCreateDiet() {
    yield takeEvery(DietActionTypes.CREATE_DIET, createDiet);
}

function* clientsSaga() {
    yield all([fork(watchGetDiet), fork(watchCreateDiet), fork(watchPostEditDiet), fork(watchDeleteDiet)]);
}

export default clientsSaga;
