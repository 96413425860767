import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers

import {
    delete_checkin,
    update_checkin,
    create_checkin,
    get_checkin,
    get_all_checkin,
    get_user_checkins,
} from '../../helpers';
// actions
import { checkinsApiResponseError, checkinsApiResponseSuccess } from './actions';

// constants
import { CheckinsActionTypes } from './constants';

type CheckinSagaActionPayload = {
    payload: any;
    type: string;
};

/**
 * Get clients
 * @param {*}
 */
function* getCheckins(): SagaIterator {
    try {
        const response = yield call(get_all_checkin);
        const clients = response.data;
        yield put(checkinsApiResponseSuccess(CheckinsActionTypes.GET_ALL_CHECKINS, clients));
    } catch (error: any) {
        yield put(checkinsApiResponseError(CheckinsActionTypes.GET_ALL_CHECKINS, error));
    }
}

function* findOneCheckin({ payload: { checkinId } }: CheckinSagaActionPayload): SagaIterator {
    try {
        const response = yield call(get_checkin, { checkinId });
        const client = response.data;
        yield put(checkinsApiResponseSuccess(CheckinsActionTypes.GET, client));
    } catch (error: any) {
        yield put(checkinsApiResponseError(CheckinsActionTypes.GET, error));
    }
}

function* createCheckins({ payload: { data } }: CheckinSagaActionPayload): SagaIterator {
    try {
        const response = yield call(create_checkin, { data });
        const clients = response.data;
        yield put(checkinsApiResponseSuccess(CheckinsActionTypes.CREATE_CHECKIN, clients));
        yield call(getCheckins);
    } catch (error: any) {
        yield put(checkinsApiResponseError(CheckinsActionTypes.CREATE_CHECKIN, error));
    }
}

function* updateCheckin({ payload: { data, checkinId } }: CheckinSagaActionPayload): SagaIterator {
    try {
        const response = yield call(update_checkin, { data, checkinId });
        const clients = response.data;
        yield put(checkinsApiResponseSuccess(CheckinsActionTypes.UPDATE_CHECKIN, clients));
        yield call(getCheckins);
    } catch (error: any) {
        yield put(checkinsApiResponseError(CheckinsActionTypes.UPDATE_CHECKIN, error));
    }
}

function* deleteCheckin({ payload: { data, checkinId } }: CheckinSagaActionPayload): SagaIterator {
    try {
        const response = yield call(delete_checkin, { checkinId });
        const clients = response.data;
        yield put(checkinsApiResponseSuccess(CheckinsActionTypes.DELETE_CHECKIN, clients));
        yield call(getCheckins);
    } catch (error: any) {
        yield put(checkinsApiResponseError(CheckinsActionTypes.DELETE_CHECKIN, error));
    }
}

function* getUserCheckins({ payload: { userId } }: CheckinSagaActionPayload): SagaIterator {
    try {
        const response = yield call(get_user_checkins, { userId });
        const checkins = response.data;
        yield put(checkinsApiResponseSuccess(CheckinsActionTypes.GET_USER_CHECKINS, checkins));
    } catch (error: any) {
        yield put(checkinsApiResponseError(CheckinsActionTypes.GET_USER_CHECKINS, error));
    }
}

export function* watchFindOneCheckin() {
    yield takeEvery(CheckinsActionTypes.GET, findOneCheckin);
}

export function* watchGetCheckins() {
    yield takeEvery(CheckinsActionTypes.GET_ALL_CHECKINS, getCheckins);
}

export function* watchCreateCheckins() {
    yield takeEvery(CheckinsActionTypes.CREATE_CHECKIN, createCheckins);
}

export function* watchUpdateCheckin() {
    yield takeEvery(CheckinsActionTypes.UPDATE_CHECKIN, updateCheckin);
}

export function* watchDeleteCheckin() {
    yield takeEvery(CheckinsActionTypes.DELETE_CHECKIN, deleteCheckin);
}

export function* watchGetUserCheckins() {
    yield takeEvery(CheckinsActionTypes.GET_USER_CHECKINS, getUserCheckins);
}

function* clientsSaga() {
    yield all([
        fork(watchGetCheckins),
        fork(watchCreateCheckins),
        fork(watchFindOneCheckin),
        fork(watchUpdateCheckin),
        fork(watchDeleteCheckin),
        fork(watchGetUserCheckins),
    ]);
}

export default clientsSaga;
