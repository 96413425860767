export type Client = {
    id: number;
    avatar: string;
    username: string;
    email: string;
};

export interface DietPlan {
    meals: Meal[];
    id: string;
    diet: string;
    mealFrequencyText: string;
    dietPlanSubtitle: string;
    snackFrequencyText: string;
    waterLiters: string;
    cheatMealNumber: string;
    cardio: string;
    version: DietVersion;
}
export interface Meal {
    id?: string;
    mealTitle: string;
    mealText: string;
    mealSubtitle: string;
    richFields: {
        mealText: string;
    };
}

export enum DietVersion {
    V1 = 'V1',
    V2 = 'V2',
}
