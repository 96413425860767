// constants
import { CheckinsActionTypes } from './constants';

export type CheckinActionType = {
    type: CheckinsActionTypes;
    payload: {} | string;
};

// common success
export const checkinsApiResponseSuccess = (actionType: string, data: any): CheckinActionType => ({
    type: CheckinsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const checkinsApiResponseError = (actionType: string, error: string): CheckinActionType => ({
    type: CheckinsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCheckins = (): CheckinActionType => ({
    type: CheckinsActionTypes.GET_ALL_CHECKINS,
    payload: {},
});

export const getUserCheckins = (userId: any): CheckinActionType => ({
    type: CheckinsActionTypes.GET_USER_CHECKINS,
    payload: { userId },
});

export const findOneCheckin = (checkinId: any): CheckinActionType => ({
    type: CheckinsActionTypes.GET,
    payload: { checkinId },
});

export const createCheckin = (data: any): CheckinActionType => ({
    type: CheckinsActionTypes.CREATE_CHECKIN,
    payload: { data },
});

export const updateCheckin = (data: any, checkinId: any): CheckinActionType => ({
    type: CheckinsActionTypes.UPDATE_CHECKIN,
    payload: { data, checkinId },
});

export const deleteCheckin = (checkinId: any): CheckinActionType => ({
    type: CheckinsActionTypes.DELETE_CHECKIN,
    payload: { checkinId },
});
