import { APICore } from './apiCore';

const api = new APICore();

// get all
function get_diet(params: { clientId: number }) {
    const baseUrl = `/diet-plans?populate=*&userId=${params.clientId}`;
    return api.get(baseUrl, undefined);
}

function update_diet(payload: { dietId: number; data: any }) {
    const baseUrl = `/diet-plans/${payload.dietId}?populate=*`;
    const data = { data: payload.data };
    return api.update(`${baseUrl}`, data);
}

function create_diet(payload: any) {
    const baseUrl = `/diet-plans/`;
    const data = { data: payload.data };
    return api.create(`${baseUrl}`, data);
}

function delete_diet(params: { dietId: number }) {
    const baseUrl = `/diet-plans/${params.dietId}`;
    return api.delete(`${baseUrl}`);
}

export { delete_diet, update_diet, create_diet, get_diet };
