enum MealTemplatesActionTypes {
    API_RESPONSE_SUCCESS = '@@mealTemplates/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@mealTemplates/API_RESPONSE_ERROR',

    UPDATE_MEAL_TEMPLATE = '@@mealTemplates/UPDATE_MEAL_TEMPLATE',
    GET = '@@mealTemplates/GET',
    GET_ALL_MEAL_TEMPLATES = '@@mealTemplates/GET_ALL_MEAL_TEMPLATES',
    CREATE_MEAL_TEMPLATE = '@@mealTemplates/CREATE_MEAL_TEMPLATE',
    DELETE_MEAL_TEMPLATE = '@@mealTemplates/DELETE_MEAL_TEMPLATE',

    RESET = '@@mealTemplates/RESET',
}

enum MealTemplatesActionSuccessMessage {
    CREATE_MEAL_TEMPLATE = 'Meal Template successfully created!',
    UPDATE_MEAL_TEMPLATE = 'Meal Template successfully updated!',
    DELETE_MEAL_TEMPLATE = 'Meal Template successfully deleted!',
}

export { MealTemplatesActionTypes, MealTemplatesActionSuccessMessage };
