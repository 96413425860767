// constants
import { DietPlan, DietVersion } from '../../pages/Clients/types';
import { DietActionTypes } from './constants';

const INIT_STATE: State = {
    data: {
        data: {
            mealFrequencyText: '',
            meals: [],
            id: '0',
            diet: '',
            dietPlanSubtitle: '',
            snackFrequencyText: '',
            waterLiters: '',
            cheatMealNumber: '',
            cardio: '',
            version: DietVersion.V1,
        },
    },
    loading: true,
    error: false,
    errorText: '',
};

type DietActionType = {
    type: DietActionTypes;
    payload: any;
};

type State = {
    data: { data: DietPlan };
    loading: boolean;
    error: boolean;
    errorText: string;
};

const Diet = (state: State = INIT_STATE, action: DietActionType): State => {
    switch (action.type) {
        case DietActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DietActionTypes.GET_DIET:
                    return {
                        ...state,
                        data: { data: action.payload.data.data as DietPlan },
                        loading: false,
                        errorText: '',
                        error: false,
                    };

                case DietActionTypes.POST_UPDATE_DIET:
                    return {
                        ...state,
                        loading: false,
                        errorText: '',
                        error: false,
                    };
                default:
                    return { ...state };
            }

        case DietActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DietActionTypes.GET_DIET: {
                    return {
                        ...state,
                        error: !!action.payload.error,
                        errorText: action.payload.error.message ?? '',
                        loading: false,
                    };
                }
                case DietActionTypes.POST_UPDATE_DIET:
                    return {
                        ...state,
                        error: !!action.payload.error,
                        errorText: action.payload.error.message ?? '',
                        loading: false,
                    };
                default:
                    return { ...state };
            }

        case DietActionTypes.RESET:
            // Explicitly reset both data and editedDiet to ensure the state is fully cleared
            return {
                ...INIT_STATE,
                loading: false,
                error: false,
                errorText: '',
            };

        default:
            return state;
    }
};

export default Diet;
