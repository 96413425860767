enum ClientsActionTypes {
    API_RESPONSE_SUCCESS = '@@clients/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@clients/API_RESPONSE_ERROR',

    UPDATE_CLIENT = '@@clients/UPDATE_CLIENT',
    FIND_ONE = '@@clients/FIND_ONE',
    GET_CLIENTS = '@@clients/GET_CLIENTS',
    CREATE_CLIENT = '@@clients/CREATE_CLIENT',
    DELETE_ONE = '@@clients/DELETE_ONE',
    GET_ME = '@@clients/GET_ME',

    RESET = '@@clients/RESET',
}

enum ClientsActionSuccessMessage {
    CREATE_CLIENT = 'Client successfully created!',
    UPDATE_CLIENT = 'Client successfully updated!',
    DELETE_CLIENT = 'Client successfully deleted!',
}

export { ClientsActionTypes, ClientsActionSuccessMessage };
