// constants
import { ClientsActionSuccessMessage, ClientsActionTypes } from './constants';

const INIT_STATE = {
    clients: [],
    loading: false,
    error: null,
    successMessage: null,
    createClientError: null,
};

export type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    blocked: boolean;
    confirmed: boolean;
    forcePasswordReset: boolean;
    checkinDays: string[];
    role: string;
    checkin_subtitle: string;
    token: string;
};

type ClientActionType = {
    type: ClientsActionTypes;
    payload: {
        actionType?: string;
        data?: UserData | {};
        error?: any;
        clientId?: string;
    };
};

type State = {
    clients?: UserData[] | {};
    loading?: boolean;
    value?: boolean;
};

const Client = (state: State = INIT_STATE, action: ClientActionType): any => {
    switch (action.type) {
        case ClientsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ClientsActionTypes.GET_CLIENTS:
                    return {
                        ...state,
                        clients: action.payload.data,
                        loading: false,
                    };
                case ClientsActionTypes.FIND_ONE:
                    return {
                        ...state,
                        clients: action.payload.data,
                        loading: false,
                    };
                case ClientsActionTypes.CREATE_CLIENT:
                    return {
                        ...state,
                        loading: false,
                        successMessage: ClientsActionSuccessMessage.CREATE_CLIENT,
                        createClientError: null,
                    };
                case ClientsActionTypes.DELETE_ONE:
                    return {
                        ...state,
                        successMessage: ClientsActionSuccessMessage.DELETE_CLIENT,
                        loading: false,
                    };

                case ClientsActionTypes.UPDATE_CLIENT:
                    return {
                        ...state,
                        // clients: action.payload.data, // TODO: remove this and update the client on updats
                        loading: false,
                        successMessage: ClientsActionSuccessMessage.UPDATE_CLIENT,
                    };
                default:
                    return { ...state };
            }

        case ClientsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ClientsActionTypes.GET_CLIENTS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ClientsActionTypes.FIND_ONE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case ClientsActionTypes.CREATE_CLIENT: {
                    let createClientError = [action?.payload?.error];
                    const errorDetails = action?.payload?.error?.error?.response?.data?.error?.details.errors;

                    if (errorDetails?.length) {
                        createClientError = errorDetails;
                    }
                    return {
                        ...state,
                        loading: false,
                        successMessage: null,
                        createClientError,
                    };
                }
                case ClientsActionTypes.UPDATE_CLIENT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        successMessage: null,
                    };
                }
                default:
                    return { ...state };
            }
        case ClientsActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                clients: [],
            };
        default:
            return { ...state };
    }
};

export default Client;
