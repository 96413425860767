// constants
import { ClientsActionTypes } from './constants';

export type ClientActionType = {
    type: ClientsActionTypes;
    payload: {} | string;
};

type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

// common success
export const clientsApiResponseSuccess = (actionType: string, data: UserData | {}): ClientActionType => ({
    type: ClientsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const clientsApiResponseError = (actionType: string, error: string): ClientActionType => ({
    type: ClientsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getClients = (): ClientActionType => ({
    type: ClientsActionTypes.GET_CLIENTS,
    payload: {},
});

export const getMe = (): ClientActionType => ({
    type: ClientsActionTypes.GET_ME,
    payload: {},
});

export const findOneClient = (clientId: any): ClientActionType => ({
    type: ClientsActionTypes.FIND_ONE,
    payload: { clientId },
});

export const createClient = (data: any): ClientActionType => ({
    type: ClientsActionTypes.CREATE_CLIENT,
    payload: { data },
});

export const updateClient = (data: any, clientId: any): ClientActionType => ({
    type: ClientsActionTypes.UPDATE_CLIENT,
    payload: { data, clientId },
});

export const deleteClient = (clientId: any): ClientActionType => ({
    type: ClientsActionTypes.DELETE_ONE,
    payload: { clientId },
});
