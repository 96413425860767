// constants
import { TrainingPlanActionTypes } from './constants';

export type TrainingPlanActionType = {
    type: TrainingPlanActionTypes;
    payload: {} | string;
};

// common success
export const trainingPlansApiResponseSuccess = (actionType: string, data: any): TrainingPlanActionType => ({
    type: TrainingPlanActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const trainingPlansApiResponseError = (actionType: string, error: string): TrainingPlanActionType => ({
    type: TrainingPlanActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTrainingPlan = (clientId: number): TrainingPlanActionType => ({
    type: TrainingPlanActionTypes.GET_TRAINING_PLAN,
    payload: { clientId },
});

export const createTrainingPlan = (payload: any): TrainingPlanActionType => ({
    type: TrainingPlanActionTypes.CREATE_TRAINING_PLAN,
    payload: { ...payload },
});

export const deleteTrainingPlan = (trainingPlanId: number): TrainingPlanActionType => ({
    type: TrainingPlanActionTypes.DELETE_TRAINING_PLAN,
    payload: { id: trainingPlanId },
});

export const updateTrainingPlan = (payload: any): TrainingPlanActionType => {
    return {
        type: TrainingPlanActionTypes.UPDATE_TRAINING_PLAN,
        payload,
    };
};
