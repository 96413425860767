enum DietActionTypes {
    API_RESPONSE_SUCCESS = '@@diets/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@diets/API_RESPONSE_ERROR',

    GET_DIET = '@@diets/GET_DIET',
    DELETE_DIET = '@@diets/DELTE_DIET',
    UPDATE_DIET = '@@diets/EDIT_DIET',
    CREATE_DIET = '@@diets/CREATE_DIET',
    POST_UPDATE_DIET = '@@diets/POST_UPDATE_DIET',

    ADD_MEAL = '@@diets/ADD_MEAL',
    EDIT_MEAL = '@@diets/EDIT_MEAL',
    DELETE_MEAL = '@@diets/DELETE_MEAL',
    REORDER_MEALS = '@@diets/REORDER_MEALS',

    EDIT_FIELD = '@@diets/EDIT_FIELD',
    EDIT_MEAL_FIELD = '@@diets/EDIT_MEAL_MARKDOWN_FIELD',

    RESET = '@@diets/RESET',
}

export { DietActionTypes };
