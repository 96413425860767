import { APICore } from './apiCore';

const api = new APICore();

function get_meal_template(params: { mealTemplateId: number }) {
    const baseUrl = `/meal-templates/${params.mealTemplateId}`;
    return api.get(baseUrl, undefined);
}

function get_all_meal_template() {
    const baseUrl = `/meal-templates?populate=*`;
    return api.get(baseUrl, undefined);
}

function update_meal_template(payload: { mealTemplateId: number; data: any }) {
    const baseUrl = `/meal-templates/${payload.mealTemplateId}`;
    const data = { data: payload.data };
    return api.update(`${baseUrl}`, data);
}

function create_meal_template(payload: any) {
    const baseUrl = `/meal-templates/`;
    const data = { data: payload.data };
    return api.create(`${baseUrl}`, data);
}

function delete_meal_template(params: { mealTemplateId: number }) {
    const baseUrl = `/meal-templates/${params.mealTemplateId}`;
    return api.delete(`${baseUrl}`);
}

export { delete_meal_template, update_meal_template, create_meal_template, get_meal_template, get_all_meal_template };
