// constants
import { MealTemplatesActionSuccessMessage, MealTemplatesActionTypes } from './constants';

const INIT_STATE = {
    mealTemplates: [],
    loading: false,
    error: null,
};

type MealTemplateActionType = {
    type: MealTemplatesActionTypes;
    payload: {
        actionType?: string;
        data?: any | {};
        error?: string;
        mealTemplateId?: string;
    };
};

type State = {
    mealTemplates: any[];
    loading?: boolean;
    value?: boolean;
    successMessage?: string;
    error?: any;
};

const MealTemplate = (state: State = INIT_STATE, action: MealTemplateActionType): any => {
    switch (action.type) {
        case MealTemplatesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MealTemplatesActionTypes.GET_ALL_MEAL_TEMPLATES:
                    return {
                        ...state,
                        mealTemplates: action.payload.data,
                        loading: false,
                    };
                case MealTemplatesActionTypes.GET: {
                    return {
                        ...state,
                        mealTemplates: action.payload.data,
                        loading: false,
                    };
                }
                case MealTemplatesActionTypes.CREATE_MEAL_TEMPLATE:
                    return {
                        ...state,
                        loading: false,
                        successMessage: MealTemplatesActionSuccessMessage.CREATE_MEAL_TEMPLATE,
                        error: null,
                    };
                case MealTemplatesActionTypes.UPDATE_MEAL_TEMPLATE:
                    return {
                        ...state,
                        mealTemplate: action.payload.data,
                        loading: false,
                        successMessage: MealTemplatesActionSuccessMessage.UPDATE_MEAL_TEMPLATE,
                        error: null,
                    };
                case MealTemplatesActionTypes.DELETE_MEAL_TEMPLATE:
                    return {
                        ...state,
                        mealTemplate: action.payload.data,
                        loading: false,
                        successMessage: MealTemplatesActionSuccessMessage.DELETE_MEAL_TEMPLATE,
                        error: null,
                    };
                default:
                    return { ...state };
            }

        case MealTemplatesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MealTemplatesActionTypes.GET_ALL_MEAL_TEMPLATES: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case MealTemplatesActionTypes.GET: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case MealTemplatesActionTypes.CREATE_MEAL_TEMPLATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case MealTemplatesActionTypes.UPDATE_MEAL_TEMPLATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                case MealTemplatesActionTypes.DELETE_MEAL_TEMPLATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case MealTemplatesActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                mealTemplates: [],
            };
        default:
            return { ...state };
    }
};

export default MealTemplate;
