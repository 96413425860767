enum TrainingPlanActionTypes {
    API_RESPONSE_SUCCESS = '@@trainingPlans/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@trainingPlans/API_RESPONSE_ERROR',

    GET_TRAINING_PLAN = '@@trainingPlans/GET_TRAINING_PLAN',
    DELETE_TRAINING_PLAN = '@@trainingPlans/DELTE_TRAINING_PLAN',
    UPDATE_TRAINING_PLAN = '@@trainingPlans/UPDATE_TRAINING_PLAN',
    CREATE_TRAINING_PLAN = '@@trainingPlans/CREATE_TRAINING_PLAN',

    RESET = '@@trainingPlans/RESET',
}

export { TrainingPlanActionTypes };
