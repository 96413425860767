// constants
import { LayoutActionTypes } from './constants';

export type LayoutActionType<TPayload> = {
    type: LayoutActionTypes;
    payload?: TPayload;
};

export const changeLayout = (layout: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT,
    payload: layout,
});

export const changeLayoutColor = (color: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT_COLOR,
    payload: color,
});

export const changeLayoutWidth = (width: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
    payload: width,
});

export const changeMenuPositions = (position: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_MENU_POSITIONS,
    payload: position,
});

export const changeSidebarTheme = (sidebarTheme: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
    payload: sidebarTheme,
});

export const changeSidebarType = (sidebarType: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_SIDEBAR_TYPE,
    payload: sidebarType,
});

export const toggleSidebarUserInfo = (showSidebarUserInfo: boolean): LayoutActionType<boolean> => ({
    type: LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO,
    payload: showSidebarUserInfo,
});

export const changeTopbarTheme = (topbarTheme: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_TOPBAR_THEME,
    payload: topbarTheme,
});

export const showRightSidebar = (): LayoutActionType<null> => ({
    type: LayoutActionTypes.SHOW_RIGHT_SIDEBAR,
});

export const hideRightSidebar = (): LayoutActionType<null> => ({
    type: LayoutActionTypes.HIDE_RIGHT_SIDEBAR,
});

export const toggleTopBar = (payload: boolean): LayoutActionType<boolean> => ({
    type: LayoutActionTypes.TOGGLE_TOP_BAR,
    payload,
});
