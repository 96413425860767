// constants
import { TrainingPlanActionTypes } from './constants';

const INIT_STATE = {
    trainingPlans: [],
    loading: false,
};

type TrainingPlanActionType = {
    type: TrainingPlanActionTypes;
    payload: {
        actionType?: string;
        data?: any | {};
        error?: string;
        trainingPlanId?: string;
    };
};

type State = {
    trainingPlan?: any[] | {};
    loading?: boolean;
    value?: boolean;
};

const TrainingPlan = (state: State = INIT_STATE, action: TrainingPlanActionType): any => {
    switch (action.type) {
        case TrainingPlanActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TrainingPlanActionTypes.GET_TRAINING_PLAN:
                    return {
                        ...state,
                        trainingPlans: action.payload.data,
                        loading: false,
                    };
                case TrainingPlanActionTypes.CREATE_TRAINING_PLAN:
                    return {
                        ...state,
                        loading: false,
                    };
                case TrainingPlanActionTypes.UPDATE_TRAINING_PLAN:
                    return {
                        ...state,
                        trainingPlan: action.payload.data,
                        loading: false,
                    };
                case TrainingPlanActionTypes.DELETE_TRAINING_PLAN:
                    return {
                        ...state,
                        trainingPlan: action.payload.data,
                        loading: false,
                    };
                default:
                    return { ...state };
            }

        case TrainingPlanActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TrainingPlanActionTypes.GET_TRAINING_PLAN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TrainingPlanActionTypes.CREATE_TRAINING_PLAN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case TrainingPlanActionTypes.UPDATE_TRAINING_PLAN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                case TrainingPlanActionTypes.DELETE_TRAINING_PLAN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case TrainingPlanActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                trainingPlans: [],
            };
        default:
            return { ...state };
    }
};

export default TrainingPlan;
