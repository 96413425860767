// constants
import { CheckinsActionSuccessMessage, CheckinsActionTypes } from './constants';

const INIT_STATE = {
    checkins: [],
    loading: false,
    error: null,
};

type CheckinActionType = {
    type: CheckinsActionTypes;
    payload: {
        actionType?: string;
        data?: any | {};
        error?: string;
        checkinId?: string;
    };
};

type State = {
    checkins: any[];
    loading?: boolean;
    value?: boolean;
    successMessage?: string;
    error?: any;
};

const Checkin = (state: State = INIT_STATE, action: CheckinActionType): any => {
    switch (action.type) {
        case CheckinsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CheckinsActionTypes.GET_ALL_CHECKINS:
                    return {
                        ...state,
                        checkins: action.payload.data,
                        loading: false,
                    };

                case CheckinsActionTypes.GET_USER_CHECKINS:
                    return {
                        ...state,
                        checkins: action.payload.data,
                        loading: false,
                    };
                case CheckinsActionTypes.GET: {
                    return {
                        ...state,
                        checkins: action.payload.data,
                        loading: false,
                    };
                }
                case CheckinsActionTypes.CREATE_CHECKIN:
                    return {
                        ...state,
                        loading: false,
                        successMessage: CheckinsActionSuccessMessage.CREATE_CHECKIN,
                        error: null,
                    };
                case CheckinsActionTypes.UPDATE_CHECKIN:
                    return {
                        ...state,
                        checkin: action.payload.data,
                        loading: false,
                        successMessage: CheckinsActionSuccessMessage.UPDATE_CHECKIN,
                        error: null,
                    };
                case CheckinsActionTypes.DELETE_CHECKIN:
                    return {
                        ...state,
                        checkin: action.payload.data,
                        loading: false,
                        successMessage: CheckinsActionSuccessMessage.DELETE_CHECKIN,
                        error: null,
                    };
                default:
                    return { ...state };
            }

        case CheckinsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CheckinsActionTypes.GET_ALL_CHECKINS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CheckinsActionTypes.GET_USER_CHECKINS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CheckinsActionTypes.GET: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CheckinsActionTypes.CREATE_CHECKIN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case CheckinsActionTypes.UPDATE_CHECKIN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                case CheckinsActionTypes.DELETE_CHECKIN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }
        case CheckinsActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                checkins: [],
            };
        default:
            return { ...state };
    }
};

export default Checkin;
