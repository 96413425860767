// constants
import { DietActionTypes } from './constants';

export type DietActionType = {
    type: DietActionTypes;
    payload: {} | string;
};

type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

// common success
export const dietsApiResponseSuccess = (actionType: string, data: UserData | {}): DietActionType => ({
    type: DietActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const dietsApiResponseError = (actionType: string, error: string): DietActionType => ({
    type: DietActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getDiet = (clientId: number): DietActionType => ({
    type: DietActionTypes.GET_DIET,
    payload: { clientId },
});

export const createDiet = (payload: any): DietActionType => ({
    type: DietActionTypes.CREATE_DIET,
    payload: { ...payload },
});

export const deleteDiet = (dietId: number): DietActionType => ({
    type: DietActionTypes.DELETE_DIET,
    payload: { id: dietId },
});

export const updateDiet = (dietId: number): DietActionType => ({
    type: DietActionTypes.UPDATE_DIET,
    payload: { dietId },
});

export const editDietField = (updates: any): DietActionType => {
    return {
        type: DietActionTypes.EDIT_FIELD,
        payload: { ...updates },
    };
};

export const postUpdateDiet = (data: any, dietId: number, clientId: number): DietActionType => {
    return {
        type: DietActionTypes.POST_UPDATE_DIET,
        payload: { data, dietId, clientId },
    };
};

export const addMeal = (
    mealTitle: string,
    mealText: any,
    mealSubtitle: string,
    id: string,
    richFields: any
): DietActionType => {
    return {
        type: DietActionTypes.ADD_MEAL,
        payload: { data: { mealTitle, mealText, mealSubtitle, id, newMeal: true, richFields } },
    };
};

export const updateMealsOrder = (meals: any): DietActionType => {
    return {
        type: DietActionTypes.REORDER_MEALS,
        payload: { data: { meals } },
    };
};

export const deleteMeal = (mealId: number): DietActionType => {
    return {
        type: DietActionTypes.DELETE_MEAL,
        payload: { mealId },
    };
};

export const editDietMealField = (mealId: number, field: any, updates: any): DietActionType => ({
    type: DietActionTypes.EDIT_MEAL_FIELD,
    payload: { mealId, field, ...updates },
});
