import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers

import { delete_training_plan, create_training_plan, update_training_plan, get_training_plan } from '../../helpers';
// actions
import { trainingPlansApiResponseError, trainingPlansApiResponseSuccess } from './actions';

// constants
import { TrainingPlanActionTypes } from './constants';

type TrainingPlanSagaActionPayload = {
    payload: any;
    type: string;
};

function* getTrainingPlan({ payload: { clientId } }: TrainingPlanSagaActionPayload): SagaIterator {
    try {
        const response = yield call(get_training_plan, { clientId });
        const client = response.data;
        yield put(trainingPlansApiResponseSuccess(TrainingPlanActionTypes.GET_TRAINING_PLAN, client));
    } catch (error: any) {
        yield put(trainingPlansApiResponseError(TrainingPlanActionTypes.GET_TRAINING_PLAN, error));
    }
}

function* createTrainingPlans({ payload: { data } }: TrainingPlanSagaActionPayload): SagaIterator {
    try {
        const response = yield call(create_training_plan, { data });
        const clients = response.data;
        yield put(trainingPlansApiResponseSuccess(TrainingPlanActionTypes.CREATE_TRAINING_PLAN, clients));
    } catch (error: any) {
        yield put(trainingPlansApiResponseError(TrainingPlanActionTypes.CREATE_TRAINING_PLAN, error));
    }
}

function* updateTrainingPlan({ payload }: TrainingPlanSagaActionPayload): SagaIterator {
    try {
        const response = yield call(update_training_plan, payload);
        const clients = response.data;
        yield put(trainingPlansApiResponseSuccess(TrainingPlanActionTypes.UPDATE_TRAINING_PLAN, clients));
    } catch (error: any) {
        yield put(trainingPlansApiResponseError(TrainingPlanActionTypes.UPDATE_TRAINING_PLAN, error));
    }
}

function* deleteTrainingPlan({ payload: { trainingPlanId } }: TrainingPlanSagaActionPayload): SagaIterator {
    try {
        const response = yield call(delete_training_plan, { trainingPlanId });
        const clients = response.data;
        yield put(trainingPlansApiResponseSuccess(TrainingPlanActionTypes.DELETE_TRAINING_PLAN, clients));
    } catch (error: any) {
        yield put(trainingPlansApiResponseError(TrainingPlanActionTypes.DELETE_TRAINING_PLAN, error));
    }
}

export function* watchGetTrainingPlan() {
    yield takeEvery(TrainingPlanActionTypes.GET_TRAINING_PLAN, getTrainingPlan);
}

export function* watchCreateTrainingPlans() {
    yield takeEvery(TrainingPlanActionTypes.CREATE_TRAINING_PLAN, createTrainingPlans);
}

export function* watchUpdateTrainingPlan() {
    yield takeEvery(TrainingPlanActionTypes.UPDATE_TRAINING_PLAN, updateTrainingPlan);
}

export function* watchDeleteTrainingPlan() {
    yield takeEvery(TrainingPlanActionTypes.DELETE_TRAINING_PLAN, deleteTrainingPlan);
}

function* trainingSaga() {
    yield all([
        fork(watchGetTrainingPlan),
        fork(watchCreateTrainingPlans),
        fork(watchUpdateTrainingPlan),
        fork(watchDeleteTrainingPlan),
    ]);
}

export default trainingSaga;
