import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers

import {
    delete_meal_template,
    update_meal_template,
    create_meal_template,
    get_meal_template,
    get_all_meal_template,
} from '../../helpers';
// actions
import { mealTemplatesApiResponseError, mealTemplatesApiResponseSuccess } from './actions';

// constants
import { MealTemplatesActionTypes } from './constants';

type MealTemplateSagaActionPayload = {
    payload: any;
    type: string;
};

/**
 * Get clients
 * @param {*}
 */
function* getMealTemplates(): SagaIterator {
    try {
        const response = yield call(get_all_meal_template);
        const clients = response.data;
        yield put(mealTemplatesApiResponseSuccess(MealTemplatesActionTypes.GET_ALL_MEAL_TEMPLATES, clients));
    } catch (error: any) {
        yield put(mealTemplatesApiResponseError(MealTemplatesActionTypes.GET_ALL_MEAL_TEMPLATES, error));
    }
}

function* findOneMealTemplate({ payload: { mealTemplateId } }: MealTemplateSagaActionPayload): SagaIterator {
    try {
        const response = yield call(get_meal_template, { mealTemplateId });
        const client = response.data;
        yield put(mealTemplatesApiResponseSuccess(MealTemplatesActionTypes.GET, client));
    } catch (error: any) {
        yield put(mealTemplatesApiResponseError(MealTemplatesActionTypes.GET, error));
    }
}

function* createMealTemplates({ payload: { data } }: MealTemplateSagaActionPayload): SagaIterator {
    try {
        const response = yield call(create_meal_template, { data });
        const clients = response.data;
        yield put(mealTemplatesApiResponseSuccess(MealTemplatesActionTypes.CREATE_MEAL_TEMPLATE, clients));
        yield call(getMealTemplates);
    } catch (error: any) {
        yield put(mealTemplatesApiResponseError(MealTemplatesActionTypes.CREATE_MEAL_TEMPLATE, error));
    }
}

function* updateMealTemplate({ payload: { data, mealTemplateId } }: MealTemplateSagaActionPayload): SagaIterator {
    try {
        const response = yield call(update_meal_template, { data, mealTemplateId });
        const clients = response.data;
        yield put(mealTemplatesApiResponseSuccess(MealTemplatesActionTypes.UPDATE_MEAL_TEMPLATE, clients));
        yield call(getMealTemplates);
    } catch (error: any) {
        yield put(mealTemplatesApiResponseError(MealTemplatesActionTypes.UPDATE_MEAL_TEMPLATE, error));
    }
}

function* deleteMealTemplate({ payload: { data, mealTemplateId } }: MealTemplateSagaActionPayload): SagaIterator {
    try {
        const response = yield call(delete_meal_template, { mealTemplateId });
        const clients = response.data;
        yield put(mealTemplatesApiResponseSuccess(MealTemplatesActionTypes.DELETE_MEAL_TEMPLATE, clients));
        yield call(getMealTemplates);
    } catch (error: any) {
        yield put(mealTemplatesApiResponseError(MealTemplatesActionTypes.DELETE_MEAL_TEMPLATE, error));
    }
}

export function* watchFindOneMealTemplate() {
    yield takeEvery(MealTemplatesActionTypes.GET, findOneMealTemplate);
}

export function* watchGetMealTemplates() {
    yield takeEvery(MealTemplatesActionTypes.GET_ALL_MEAL_TEMPLATES, getMealTemplates);
}

export function* watchCreateMealTemplates() {
    yield takeEvery(MealTemplatesActionTypes.CREATE_MEAL_TEMPLATE, createMealTemplates);
}

export function* watchUpdateMealTemplate() {
    yield takeEvery(MealTemplatesActionTypes.UPDATE_MEAL_TEMPLATE, updateMealTemplate);
}

export function* watchDeleteMealTemplate() {
    yield takeEvery(MealTemplatesActionTypes.DELETE_MEAL_TEMPLATE, deleteMealTemplate);
}

function* clientsSaga() {
    yield all([
        fork(watchGetMealTemplates),
        fork(watchCreateMealTemplates),
        fork(watchFindOneMealTemplate),
        fork(watchUpdateMealTemplate),
        fork(watchDeleteMealTemplate),
    ]);
}

export default clientsSaga;
