enum CheckinsActionTypes {
    API_RESPONSE_SUCCESS = '@@checkins/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@checkins/API_RESPONSE_ERROR',

    UPDATE_CHECKIN = '@@checkins/UPDATE_CHECKIN',
    GET = '@@checkins/GET',
    GET_ALL_CHECKINS = '@@checkins/GET_ALL_CHECKIN',
    GET_USER_CHECKINS = '@@checkins/GET_USER_CHECKINS',
    CREATE_CHECKIN = '@@checkins/CREATE_CHECKIN',
    DELETE_CHECKIN = '@@checkins/DELETE_CHECKIN',

    RESET = '@@checkins/RESET',
}

enum CheckinsActionSuccessMessage {
    CREATE_CHECKIN = 'Checkin successfully created!',
    UPDATE_CHECKIN = 'Checkin successfully updated!',
    DELETE_CHECKIN = 'Checkin successfully deleted!',
}

export { CheckinsActionTypes, CheckinsActionSuccessMessage };
