import { createRoot } from 'react-dom/client';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import '@cometchat/uikit-elements';

import { CometChat } from '@cometchat/chat-sdk-javascript';
import { CometChatUIKit } from '@cometchat/chat-uikit-react';
import { UIKitSettingsBuilder } from '@cometchat/uikit-shared';

export const metaInfo = {
    name: 'cometchat-chat-sample-app-react',
    version: '4.3.7',
    type: 'sample',
    platform: 'React',
};

export const AppConstants = {
    APP_ID: '257275c3e1353e7b', // PROD
    // APP_ID: '2472021170c72008', // QA
    REGION: 'US', //process.env.REACT_APP_COMET_CHAT_REGION,
};

(async () => {
    try {
        if (AppConstants.APP_ID && AppConstants.REGION) {
            const uiKitSettings = new UIKitSettingsBuilder()
                .setAppId(AppConstants.APP_ID)
                .setRegion(AppConstants.REGION)
                .subscribePresenceForAllUsers()
                .build();
            await CometChatUIKit.init(uiKitSettings);
            CometChat.setDemoMetaInfo(metaInfo);
            console.log('Comet Chat Initialization completed successfully');
        }

        const container = document.getElementById('root');
        const root = createRoot(container!);

        root.render(
            <Provider store={configureStore({})}>
                <App />
            </Provider>
        );
    } catch (error) {
        console.log('Initialization failed with error:', error);
    }
})();

reportWebVitals();
