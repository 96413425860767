import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import clientsSaga from './clients/saga';
import layoutSaga from './layout/saga';
import dietsSaga from './diets/saga';
import mealTemplatesSaga from './mealTemplates/saga';
import trainingPlansSaga from './trainingPlans/saga';
import checkinsSaga from './checkins/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(),
        clientsSaga(),
        dietsSaga(),
        mealTemplatesSaga(),
        trainingPlansSaga(),
        checkinsSaga(),
    ]);
}
