import { APICore } from './apiCore';

const api = new APICore();

// get all
function get_clients() {
    const baseUrl = '/users?populate=*';
    return api.get(`${baseUrl}`, {});
}

function get_me() {
    const baseUrl = '/users/me?populate=*';
    return api.get(`${baseUrl}`, {});
}

function find_one(payload: { clientId: string }) {
    const baseUrl = `/users/${payload.clientId}`;
    return api.get(`${baseUrl}`, {});
}

function create_client(params: { data: any }) {
    const baseUrl = '/users/';
    return api.create(`${baseUrl}`, params.data);
}

function update_client(params: { data: any; clientId: string }) {
    const baseUrl = `/users/${params.clientId}`;
    return api.update(`${baseUrl}`, params.data);
}

function delete_client(payload: { clientId: string }) {
    const baseUrl = `/users/${payload.clientId}`;
    return api.delete(`${baseUrl}`);
}
export { get_clients, find_one, get_me, create_client, update_client, delete_client };
