import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import { get_clients, create_client, update_client, find_one, delete_client } from '../../helpers/';

// actions
import { clientsApiResponseSuccess, clientsApiResponseError } from './actions';

// constants
import { ClientsActionTypes } from './constants';

type ClientSagaActionPayload = {
    payload: any;
    type: string;
};

/**
 * Get clients
 * @param {*}
 */
function* getClients(): SagaIterator {
    try {
        const response = yield call(get_clients);
        const clients = response.data;
        yield put(clientsApiResponseSuccess(ClientsActionTypes.GET_CLIENTS, clients));
    } catch (error: any) {
        yield put(clientsApiResponseError(ClientsActionTypes.GET_CLIENTS, error));
    }
}

function* findOneClient({ payload: { clientId } }: ClientSagaActionPayload): SagaIterator {
    try {
        const response = yield call(find_one, { clientId });
        const client = response.data;
        yield put(clientsApiResponseSuccess(ClientsActionTypes.FIND_ONE, client));
    } catch (error: any) {
        yield put(clientsApiResponseError(ClientsActionTypes.FIND_ONE, error));
    }
}

function* createClients({ payload: { data } }: ClientSagaActionPayload): SagaIterator {
    try {
        const response = yield call(create_client, { data });
        const clients = response.data;
        yield put(clientsApiResponseSuccess(ClientsActionTypes.CREATE_CLIENT, clients));
        yield call(getClients);
    } catch (error: any) {
        yield put(clientsApiResponseError(ClientsActionTypes.CREATE_CLIENT, error));
    }
}

function* updateClient({ payload: { data, clientId } }: ClientSagaActionPayload): SagaIterator {
    try {
        const response = yield call(update_client, { data, clientId });
        const clients = response.data;
        yield put(clientsApiResponseSuccess(ClientsActionTypes.UPDATE_CLIENT, clients));
        const request: ClientSagaActionPayload = { payload: { clientId }, type: '' };
        yield call(findOneClient, request);
    } catch (error: any) {
        yield put(clientsApiResponseError(ClientsActionTypes.UPDATE_CLIENT, error));
    }
}

function* deleteClient({ payload: { clientId } }: ClientSagaActionPayload): SagaIterator {
    try {
        const response = yield call(delete_client, { clientId });
        const client = response.data;
        yield put(clientsApiResponseSuccess(ClientsActionTypes.DELETE_ONE, client));
        yield call(getClients);
    } catch (error: any) {
        yield put(clientsApiResponseError(ClientsActionTypes.DELETE_ONE, error));
    }
}

export function* watchFindOneClient() {
    yield takeEvery(ClientsActionTypes.FIND_ONE, findOneClient);
}

export function* watchGetClients() {
    yield takeEvery(ClientsActionTypes.GET_CLIENTS, getClients);
}

export function* watchCreateClients() {
    yield takeEvery(ClientsActionTypes.CREATE_CLIENT, createClients);
}
export function* watchUpdateClient() {
    yield takeEvery(ClientsActionTypes.UPDATE_CLIENT, updateClient);
}

export function* watchDeleteClient() {
    yield takeEvery(ClientsActionTypes.DELETE_ONE, deleteClient);
}

function* clientsSaga() {
    yield all([
        fork(watchGetClients),
        fork(watchCreateClients),
        fork(watchFindOneClient),
        fork(watchUpdateClient),
        fork(watchDeleteClient),
    ]);
}

export default clientsSaga;
