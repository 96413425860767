import { APICore } from './apiCore';

const api = new APICore();

function get_checkin(params: { checkinId: number }) {
    const baseUrl = `/checkin-submissions/${params.checkinId}`;
    return api.get(baseUrl, undefined);
}

function get_all_checkin() {
    const baseUrl = `/checkin-submissions?populate=*`;
    return api.get(baseUrl, undefined);
}

function get_user_checkins(params: { userId: number }) {
    const baseUrl = `/checkin-submissions?userId=${params.userId}&populate=*`;
    return api.get(baseUrl, undefined);
}

function update_checkin(payload: { checkinId: number; data: any }) {
    const baseUrl = `/checkin-submissions/${payload.checkinId}`;
    const data = { data: payload.data };
    return api.update(`${baseUrl}`, data);
}

function create_checkin(payload: any) {
    const baseUrl = `/checkin-submissions/`;
    const data = { data: payload.data };
    return api.create(`${baseUrl}`, data);
}

function delete_checkin(params: { checkinId: number }) {
    const baseUrl = `/checkin-submissions/${params.checkinId}`;
    return api.delete(`${baseUrl}`);
}

export { delete_checkin, update_checkin, create_checkin, get_checkin, get_all_checkin, get_user_checkins };
